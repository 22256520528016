// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c02EMI2dC1eDYYcP7NMr{display:flex;width:100vw;height:100vh;justify-content:center;align-items:center}.c02EMI2dC1eDYYcP7NMr>.LBALWvGGQ0YY6Y86jBPQ{display:flex;width:350px;flex-flow:column nowrap;align-items:center;row-gap:10px}.c02EMI2dC1eDYYcP7NMr>.LBALWvGGQ0YY6Y86jBPQ img{width:100px;height:100px}.PlPfcyIGh51NbjtSttjT{color:#9c4747;font-size:12px}", "",{"version":3,"sources":["webpack://./src/features/auth/views/SignInView/view.module.scss","webpack://./src/common/styles/colors.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,WAAA,CACA,YAAA,CAEA,sBAAA,CACA,kBAAA,CAGF,4CACE,YAAA,CACA,WAAA,CAEA,uBAAA,CACA,kBAAA,CACA,YAAA,CAEA,gDACE,WAAA,CACA,YAAA,CAIJ,sBACE,aC1BM,CD2BN,cAAA","sourcesContent":["@use 'common/styles/colors.scss' as colors;\n\n.main {\n  display: flex;\n  width: 100vw;\n  height: 100vh;\n\n  justify-content: center;\n  align-items: center;\n}\n\n.main > .container {\n  display: flex;\n  width: 350px;\n\n  flex-flow: column nowrap;\n  align-items: center;\n  row-gap: 10px ;\n\n  img {\n    width: 100px;\n    height: 100px;\n  }\n}\n\n.error{\n  color: colors.$error;\n  font-size: 12px;\n}","$error: rgb(156, 71, 71);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "c02EMI2dC1eDYYcP7NMr",
	"container": "LBALWvGGQ0YY6Y86jBPQ",
	"error": "PlPfcyIGh51NbjtSttjT"
};
export default ___CSS_LOADER_EXPORT___;
