// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fMRGNdSK9hEIG1VuDod_{display:flex;width:300px;flex-flow:column nowrap;row-gap:5px;font-family:mojangles,monospace;text-shadow:2px 2px 0px rgba(0,0,0,.5);font-size:14px;letter-spacing:.5px;color:#fff}.AoZWh6uvLqHZm6mgd68V{display:flex;width:100%;border:2px solid #aaa;background-color:#000;cursor:pointer;padding:12px 6px;outline:none;font-family:mojangles,monospace;text-shadow:2px 2px 0px rgba(0,0,0,.5);font-size:14px;letter-spacing:.5px;color:#fff}.AoZWh6uvLqHZm6mgd68V:focus{border:2px solid #fff}.RsBHWat17gOP9r_9psAg{color:#9c4747;font-size:12px}", "",{"version":3,"sources":["webpack://./src/common/components/Input/component.module.scss","webpack://./src/common/styles/font.scss","webpack://./src/common/styles/colors.scss"],"names":[],"mappings":"AAGA,sBACE,YAAA,CACA,WAAA,CAEA,uBAAA,CACA,WAAA,CCPA,+BAAA,CACA,sCAAA,CACA,cAAA,CACA,mBAAA,CACA,UAAA,CDQF,sBACE,YAAA,CACA,UAAA,CAEA,qBAAA,CACA,qBAAA,CACA,cAAA,CACA,gBAAA,CACA,YAAA,CCpBA,+BAAA,CACA,sCAAA,CACA,cAAA,CACA,mBAAA,CACA,UAAA,CDoBA,4BACE,qBAAA,CAIJ,sBACE,aE/BM,CFgCN,cAAA","sourcesContent":["@use 'common/styles/font.scss' as *;\n@use 'common/styles/colors.scss' as colors;\n\n.main {\n  display: flex;\n  width: 300px;\n\n  flex-flow: column nowrap;\n  row-gap: 5px;\n\n  @include base-font;\n}\n\n.input {\n  display: flex;\n  width: 100%;\n\n  border: 2px solid #aaaaaa;\n  background-color: #000000;\n  cursor: pointer;\n  padding: 12px 6px;\n  outline: none;\n\n  @include base-font;\n\n  &:focus {\n    border: 2px solid #ffffff;\n  }\n}\n\n.error {\n  color: colors.$error;\n  font-size: 12px;\n}\n","@mixin base-font {\n  font-family: mojangles, monospace;\n  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);\n  font-size: 14px;\n  letter-spacing: 0.5px;\n  color: white;\n}\n","$error: rgb(156, 71, 71);"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "fMRGNdSK9hEIG1VuDod_",
	"input": "AoZWh6uvLqHZm6mgd68V",
	"error": "RsBHWat17gOP9r_9psAg"
};
export default ___CSS_LOADER_EXPORT___;
