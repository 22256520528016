import { InvocationType, Lambda } from '@aws-sdk/client-lambda';
import { Function, FunctionResources } from 'sst/node/function';

import { DeploymentRegions } from '@veriok/infra';

import { EnvironmentContext } from '../index.ts';

const lambda = new Lambda({
  region: DeploymentRegions[EnvironmentContext.stage]
});

const invokeLambdaByArn = async (
  payload: any,
  lambdaArn: string,
  invocationType: InvocationType
) => {
  const response = await lambda.invoke({
    FunctionName: lambdaArn,
    Payload: payload,
    InvocationType: invocationType
  });

  if ((response.StatusCode ?? 0) < 200 && (response.StatusCode ?? 0) >= 300) {
    throw new Error(
      `Invalid lambda invocation response => bad status code ${response.StatusCode}`
    );
  }

  if (response.FunctionError) {
    throw new Error(
      `Invalid lambda invocation response => function error returned ${response.FunctionError}`
    );
  }

  return response;
};

const invokeLambdaResponseByArn = async (payload: any, lambdaArn: string) => {
  const response = await invokeLambdaByArn(
    payload,
    lambdaArn,
    'RequestResponse'
  );

  return response.Payload;
};

const invokeLambdaEventByArn = async (payload: any, lambdaArn: string) => {
  await invokeLambdaByArn(payload, lambdaArn, 'Event');
};

const invokeLambdaResponseByBoundResource = (
  payload: any,
  functionName: keyof FunctionResources
) => {
  return invokeLambdaResponseByArn(
    payload,
    (Function[functionName] as any).functionName
  );
};

const invokeLambdaEventByBoundResource = (
  payload: any,
  functionName: keyof FunctionResources
) => {
  return invokeLambdaEventByArn(
    payload,
    (Function[functionName] as any).functionName
  );
};

export {
  invokeLambdaByArn,
  invokeLambdaEventByArn,
  invokeLambdaResponseByArn,
  invokeLambdaEventByBoundResource,
  invokeLambdaResponseByBoundResource
};
