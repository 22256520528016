// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Fyh0wQQGBvpLYuWfoWsP{display:flex;position:fixed;top:0;left:0;z-index:-1;width:100vw;height:100vh;background-color:#000}.arDgXUJJxgXhynXwKcX9{display:grid;position:relative;top:0;left:0;grid-auto-columns:100%;grid-auto-flow:column;transition:.5s ease-out}.kcF7tI7F3EiE2roT0glN{width:100%;height:100%;object-fit:cover;filter:brightness(40%)}", "",{"version":3,"sources":["webpack://./src/common/components/ImageCarousel/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,cAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CAEA,WAAA,CACA,YAAA,CACA,qBAAA,CAGF,sBACE,YAAA,CAEA,iBAAA,CACA,KAAA,CACA,MAAA,CAEA,sBAAA,CACA,qBAAA,CACA,uBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,sBAAA","sourcesContent":[".main {\n  display: flex;\n\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: -1;\n\n  width: 100vw;\n  height: 100vh;\n  background-color: black;\n}\n\n.slider {\n  display: grid;\n\n  position: relative;\n  top: 0;\n  left: 0;\n\n  grid-auto-columns: 100%;\n  grid-auto-flow: column;\n  transition: 0.5s ease-out;\n}\n\n.image {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n  filter: brightness(\n    40%\n  ); /* Adjust the brightness value to control the darkness */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "Fyh0wQQGBvpLYuWfoWsP",
	"slider": "arDgXUJJxgXhynXwKcX9",
	"image": "kcF7tI7F3EiE2roT0glN"
};
export default ___CSS_LOADER_EXPORT___;
