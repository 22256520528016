import { useEffect } from 'react';

import { Authenticated, Button } from '@/common/components/index.ts';
import { FetchStatus } from '@/common/consts/index.ts';
import {
  useFetchableOperation,
  useFetchableResource
} from '@/common/hooks/index.ts';
import { useApiProvider } from '@/common/providers/index.ts';
import { IServerStatus } from '@veriok/lib';

import styles from './view.module.scss';

const ServerDashboardView = () => {
  const apiProvider = useApiProvider();
  const serverStatus = useFetchableResource<{ data: IServerStatus } | null>(
    null
  );
  const serverFetchableOperation = useFetchableOperation();

  const requestServerStatus = serverStatus.wrappedFetch(async () => {
    const response = await apiProvider.apiAuthenticated.get('/server/status');
    return response.data;
  });

  const doServerStart = serverFetchableOperation.wrappedFetch(async () => {
    await apiProvider.apiAuthenticated.post('/server/start');
    requestServerStatus();
  });

  const doServerStop = serverFetchableOperation.wrappedFetch(async () => {
    await apiProvider.apiAuthenticated.post('/server/stop');
    requestServerStatus();
  });

  useEffect(() => {
    requestServerStatus();
  }, []);

  if (serverStatus.resource?.data.game?.faviconDataUrl) {
    serverStatus.resource.data.game.faviconDataUrl = '';
  }

  return (
    <Authenticated>
      <span className={styles.serverStatus}>
        {serverStatus.resource !== null &&
          JSON.stringify(serverStatus.resource, null, 2)}
      </span>
      <Button
        onClick={doServerStart}
        loading={serverFetchableOperation.status === FetchStatus.PENDING}
        disabled={serverFetchableOperation.status === FetchStatus.PENDING}
      >
        Start Server
      </Button>
      <Button
        onClick={doServerStop}
        loading={serverFetchableOperation.status === FetchStatus.PENDING}
        disabled={serverFetchableOperation.status === FetchStatus.PENDING}
      >
        Stop Server
      </Button>
    </Authenticated>
  );
};

export { ServerDashboardView };
